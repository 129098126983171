<template>
  <div class="managementQuestions">
    <VideoTitle title="管理提问">
      <span class="videoTitleRight" @click="addModify">新建提问</span>
    </VideoTitle>
    <div class="managementQuestions_conter">
      <ul>
        <li v-for="(item, index) in listArr" :key="index" class="fsb">
          <span>{{ item.answer }}</span>
          <div class="divBtn">
            <span @click="editModify(item)">修改</span>
            <span @click="delRow(index, listArr)">删除</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VideoTitle from '@/components/public/VideoTitle.vue';
import { confirm } from '@/utils/public';
import { selectLists, knowledgeBaseDelete } from '@/utils/serveApi';
export default {
  name: 'managementQuestions',
  data() {
    return {
      videoId: '',
      listArr: []
    };
  },
  created() {
    const videoId = this.$route.query.videoId;
    this.videoId = videoId;
    this.getListData(videoId);
  },
  methods: {
    getListData(videoId) {
      selectLists(videoId).then(res => {
        const { data } = res;
        if (data.length) {
          this.listArr = data;
        }
      });
    },
    addModify() {
      this.$router.push({
        name: 'addQuestions',
        query: {
          videoId: this.videoId
        }
      });
    },
    editModify(item) {
      this.$router.push({
        name: 'addQuestions',
        query: item
      });
    },
    delRow(index, rows) {
      const { answer, id } = rows[index];
      confirm(`是否删除《${answer}》这条提问`, '提示').then(() => {
        knowledgeBaseDelete(id).then(res => {
          if (res.success) {
            rows.splice(index, 1);
            this.$message('删除成功');
          }
        });
      });
    }
  },
  components: { VideoTitle }
};
</script>
<style lang="scss">
.managementQuestions {
  .videoTitleRight {
    width: 112px;
    height: 40px;
    line-height: 40px;
    background: #ffb600;
    border-radius: 22px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    margin-right: 40px;
    cursor: pointer;
  }
}
</style>
<style lang="scss" scoped>
.managementQuestions {
  &_conter {
    padding: 30px 40px;
    ul {
      li {
        padding: 16px 0 18px;
        border-bottom: 2px solid #eef2f5;
        & > span {
          font-size: 20px;
        }
        .divBtn {
          & > span {
            width: 68px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border: 1px solid #000000;
            border-radius: 5px;
            cursor: pointer;
            display: inline-block;
            margin-left: 24px;
          }
        }
      }
    }
  }
}
</style>
